import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, CheckCircle, ExternalLink, Zap, Clock, Brain, Shield, Target } from 'lucide-react';

const RefinedAestheticVulnerableChatbotsSection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const vulnerabilityReasons = [
    { icon: Zap, title: "Rapid LLM Evolution", description: "New large language models (LLMs) are released weekly, offering improved capabilities and reduced inference costs." },
    { icon: Clock, title: "Evolving RAG Techniques", description: "Best practices for Retrieval Augmented Generation (RAG) chatbots evolve rapidly as new models emerge and developers experiment with various retrieval architectures." },
    {
      icon: Brain,
      title: "Overwhelming Pace of AI Development",
      description: "Constant evolution in models and RAG techniques overwhelms AI developers, shifting focus away from security concerns. AI developers are preoccupied with:",
      subItems: [
        { icon: Target, text: "Preventing hallucinations in chatbots" },
        { icon: Zap, text: "Adding context-relevant, clean documents to databases" },
        { icon: Clock, text: "Improving overall RAG chatbot performance" },
        { icon: Brain, text: "Enabling advanced features like multihop reasoning" }
      ]
    },
    { icon: Shield, title: "Emerging Threats", description: "Attackers discover new AI attack patterns weekly. This rapid pace matches AI development speed and makes protection increasingly challenging." }
  ];

  return (
    <section id="risks" className="bg-gradient-to-br from-blue-50 to-gray-100 py-20 px-4 md:px-8">
      <motion.div
        className="max-w-6xl mx-auto"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.h2 variants={itemVariants} className="text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-16 text-gray-800">
          Even the Most Advanced Chatbots Have Critical Vulnerabilities
        </motion.h2>

        <motion.div variants={itemVariants} className="grid md:grid-cols-2 gap-10 mb-16">
          <div className="bg-white rounded-lg shadow-lg p-8 border-l-4 border-red-500">
            <h3 className="text-2xl font-semibold mb-6 text-gray-800 flex items-center">
              <AlertTriangle className="w-8 h-8 mr-3 text-red-500" />
              AI Vulnerability Disclosures
            </h3>
            <p className="text-gray-700 text-lg mb-6">
              Our research team publicly disclosed an Indirect Prompt Injection vulnerability in Notion AI. This discovery highlights significant security risks in established AI chatbot products.
            </p>
            <p className="text-gray-700 text-lg mb-6">
              The wider AI security community has since identified similar vulnerabilities in other prominent chatbots, like Microsoft Copilot.
            </p>
            <a href="https://t.co/oTxdAnukmt" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 flex items-center text-lg font-medium">
              Read our disclosure
              <ExternalLink className="ml-2 w-5 h-5" />
            </a>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8 border-l-4 border-green-500">
            <h3 className="text-2xl font-semibold mb-6 text-gray-800 flex items-center">
              <CheckCircle className="w-8 h-8 mr-3 text-green-500" />
              Our Expertise
            </h3>
            <p className="text-gray-700 text-lg">
              Our team has conducted rigorous red teaming exercises and found critical vulnerabilities in AI chatbots deployed by multiple billion-dollar companies. These assessments cover diverse sectors, from technology giants to legal service providers.
            </p>
          </div>
        </motion.div>

        <motion.div variants={itemVariants} className="bg-white rounded-lg shadow-lg p-10 mb-16">
          <h3 className="text-3xl font-semibold mb-10 text-gray-800 text-center">Why Are Chatbots Vulnerable?</h3>
          <div className="space-y-10">
            {vulnerabilityReasons.map((reason, index) => (
              <div key={index} className="flex flex-col md:flex-row items-start p-6 bg-gray-50 rounded-lg">
                <reason.icon className="w-10 h-10 mr-6 text-blue-500 flex-shrink-0 mb-4 md:mb-0" />
                <div className="flex-grow">
                  <h4 className="text-xl font-semibold text-gray-800 mb-4">{reason.title}</h4>
                  <p className="text-gray-700 text-lg">{reason.description}</p>
                  {reason.subItems && (
                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                      {reason.subItems.map((subItem, subIndex) => (
                        <div key={subIndex} className="flex items-center bg-white p-4 rounded-md shadow-sm">
                          <subItem.icon className="w-6 h-6 mr-3 text-indigo-500" />
                          <p className="text-base text-gray-600">{subItem.text}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <p className="text-gray-700 text-lg mt-10 text-center">
            The rapid evolution of AI, shifting developer priorities, and emerging threats lead to critical vulnerabilities in RAG chatbots. As teams focus on enhancing functionality and performance, they often overlook security. This oversight exposes these systems to significant risks.
          </p>
        </motion.div>
      </motion.div>
    </section>
  );
};

export default RefinedAestheticVulnerableChatbotsSection;
