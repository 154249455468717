import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Bot, ChevronRight, Database, Server, Lock, Code, Zap } from 'lucide-react';

const AiSecuritySolutionsSection = () => {
  const solutions = [
    {
      title: 'AthenaAgent',
      icon: Bot,
      description: 'AthenaAgent is a powerful suite of automated red teaming agents that simulate attacks on AI applications to uncover vulnerabilities.',
      features: [
        {
          title: 'Universal LLM Application Coverage',
          icon: Code,
          description: 'AthenaAgent effectively tests any LLM-based application, including those built with:',
          subFeatures: [
            'Open-weight LLMs (e.g., Llama, Mistral, Gemma)',
            'Closed-weight LLMs (e.g., GPT, Claude, Gemini)',
            'Encoders (e.g., OpenAI embeddings, Cohere embeddings, sentence-transformers) and Cross-encoders',
            'Frameworks (e.g., LangChain, LlamaIndex)'
          ]
        },
        {
          title: 'Comprehensive CIA Triad Vulnerability Assessment',
          icon: Shield,
          description: 'AthenaAgent conducts a thorough evaluation across all aspects of the CIA (Confidentiality, Integrity, Availability) triad:',
          subFeatures: [
            'Confidentiality: Detects potential data breaches, unauthorized access points, and sensitive information exposure risks',
            'Integrity: Identifies vulnerabilities that could lead to manipulated responses',
            'Availability: Uncovers issues affecting system responsiveness, including denial of service vulnerabilities'
          ]
        },
        {
          title: 'Advanced Application Testing',
          icon: Zap,
          description: 'AthenaAgent specializes in testing cutting-edge AI applications:',
          subFeatures: [
            'RAG Chatbots: Performs in-depth security analysis of Retrieval-Augmented Generation (RAG) chatbots. Assesses vulnerabilities in information retrieval and response generation processes.',
            'Agentic Workflows: Identifies security flaws in autonomous AI agent systems. Tests complex decision-making processes and multi-step task executions.'
          ]
        }
      ]
    },
    {
      title: 'GuardrailAgent',
      icon: Shield,
      description: 'Our dynamic AI agents continuously evolve to defend against emerging threats. We ensure secure interactions and maintain data integrity.',
      subProducts: [
        {
          title: 'TokenGuard',
          icon: Lock,
          description: 'TokenGuard protects your AI applications from malicious user prompts and secures interactions.',
          features: [
            'Two-stage protection: Input sanitization and output verification',
            'Low-latency filtering optimized for high-throughput applications',
            'Contextual threat detection with behavior analysis'
          ]
        },
        {
          title: 'VectorGuard',
          icon: Database,
          description: 'VectorGuard prevents vector database poisoning to safeguard your data integrity and application reliability.',
          features: [
            'Proactive detection and mitigation of poisoning attempts during data ingestion',
            'Protection during database population without impact on inference latency',
            'Advanced defenses against subtle adversarial documents'
          ]
        }
      ]
    }
  ];

  return (
    <section id="solutions" className="bg-gradient-to-br from-gray-50 to-blue-100 py-16 px-4 md:px-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12 text-gray-800">
          We Secure Your Chatbots with Advanced AI Agents
        </h2>
        <div className="grid md:grid-cols-2 gap-8">
          {solutions.map((solution, index) => (
            <motion.div
              key={solution.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="flex items-center mb-4">
                <solution.icon className="w-12 h-12 text-blue-600 mr-4" />
                <h3 className="text-2xl font-bold text-gray-800">{solution.title}</h3>
              </div>
              <p className="text-gray-600 mb-6 text-lg">{solution.description}</p>
              {solution.features ? (
                <>
                  <h4 className="font-semibold text-xl mb-4 text-gray-800">Key Features:</h4>
                  <ul className="space-y-4">
                    {solution.features.map((feature, featureIndex) => (
                      <motion.li
                        key={featureIndex}
                        initial={{ opacity: 0, x: -10 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.3, delay: (index * 3 + featureIndex) * 0.1 }}
                      >
                        <div className="flex items-start mb-2">
                          <feature.icon className="w-6 h-6 text-blue-600 mr-2 mt-1 flex-shrink-0" />
                          <span className="font-semibold text-lg">{feature.title}</span>
                        </div>
                        <p className="ml-8 mb-2 text-gray-600">{feature.description}</p>
                        <ul className="ml-8 space-y-2">
                          {feature.subFeatures.map((subFeature, subIndex) => (
                            <motion.li
                              key={subIndex}
                              initial={{ opacity: 0, x: -5 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{ duration: 0.2, delay: (index * 5 + featureIndex * 2 + subIndex) * 0.05 }}
                              className="flex items-start"
                            >
                              <Server className="w-5 h-5 text-blue-600 mr-2 mt-1 flex-shrink-0" />
                              <span className="text-base">{subFeature}</span>
                            </motion.li>
                          ))}
                        </ul>
                      </motion.li>
                    ))}
                  </ul>
                </>
              ) : (
                <div className="space-y-6">
                  {solution.subProducts.map((subProduct, subIndex) => (
                    <div key={subProduct.title} className="bg-gray-50 rounded-lg p-4">
                      <div className="flex items-center mb-2">
                        <subProduct.icon className="w-8 h-8 text-blue-600 mr-2" />
                        <h4 className="text-xl font-semibold text-gray-800">{subProduct.title}</h4>
                      </div>
                      <p className="text-gray-600 mb-4 text-lg">{subProduct.description}</p>
                      <ul className="space-y-2">
                        {subProduct.features.map((feature, featureIndex) => (
                          <motion.li
                            key={featureIndex}
                            initial={{ opacity: 0, x: -10 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.3, delay: (index * 6 + subIndex * 3 + featureIndex) * 0.1 }}
                            className="flex items-start"
                          >
                            <Server className="w-5 h-5 text-blue-600 mr-2 mt-1 flex-shrink-0" />
                            <span className="text-base">{feature}</span>
                          </motion.li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </motion.div>
          ))}
        </div>
        <div className="text-center mt-12">
          <h3 className="text-2xl md:text-3xl font-bold text-gray-800 mb-6">
            Ready to take the next step in securing your chatbot?
          </h3>
          <motion.a
            href="https://cal.com/sachdh/15min"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-bold py-4 px-8 rounded-md transition duration-300 ease-in-out transform hover:scale-105 hover:-translate-y-1 inline-flex items-center justify-center text-lg lg:text-xl shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Book a Demo
            <ChevronRight className="ml-2 h-6 w-6" />
          </motion.a>
        </div>
      </div>
    </section>
  );
};

export default AiSecuritySolutionsSection;