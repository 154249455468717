import React, { useState, useEffect } from 'react';
import { Shield, Zap, Eye, ChevronRight, ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';

const MainBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  const companies = [
    { name: "UMass Amherst", image: "/images/img_3.png" },
    { name: "Google", image: "/images/google_logo.jpg" },
    { name: "Microsoft", image: "/images/microsoft_logo.jpg" },
    { name: "Meta", image: "/images/facebook_logo.jpg" },
    { name: "JPMorganChase", image: "/images/img_9.png" },
    { name: "Unity", image: "/images/img_8.png" },
    { name: "Samsung Research America", image: "/images/img_6.png" },
    { name: "Autodesk", image: "/images/img_7.png" },
  ];




  return (
    <div id="main" className="bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 text-gray-900 text-left flex flex-col items-center justify-center p-6 lg:p-16 overflow-hidden w-full min-h-screen relative">

      <motion.div
        className="flex flex-col max-w-2xl lg:max-w-3xl p-3 relative z-10 mt-24 lg:mt-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
<h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-4 lg:mb-6 leading-tight text-center">
  <span className="text-gray-800">We secure GenAI chatbots</span>
</h1>
        <p className="text-lg sm:text-xl lg:text-2xl mb-6 lg:mb-8 text-gray-700 font-light leading-relaxed text-center">
          Even the most advanced GenAI chatbots have critical vulnerabilities.
          <a href="#risks" className="text-blue-600 hover:text-blue-800 transition-colors duration-300">
            <sup>1 </sup>
          </a>
          Attackers can exploit these to harm businesses.
          <a href="#attacks" className="text-blue-600 hover:text-blue-800 transition-colors duration-300">
            <sup>2 </sup>
          </a>
          We secure your chatbots with advanced AI Agents.
          <a href="#solutions" className="text-blue-600 hover:text-blue-800 transition-colors duration-300">
            <sup>3</sup>
          </a>
        </p>
        <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 mb-8 lg:mb-12 justify-center">
          <a
            href="https://cal.com/sachdh/15min"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 hover:-translate-y-1 inline-flex items-center justify-center text-base lg:text-lg shadow-lg"
          >
            Book a Demo
            <ChevronRight className="ml-2 h-5 w-5" />
          </a>

        </div>
        <div className="grid grid-cols-3 gap-4 lg:gap-6 mb-8">
          {[
            { icon: Shield, text: "Anticipate Threats", color: "text-blue-600" },
            { icon: Zap, text: "Rapid Detection", color: "text-yellow-600" },
            { icon: Eye, text: "Continuous Monitoring", color: "text-green-600" },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="flex flex-col items-center text-center group"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className={`w-12 h-12 lg:w-16 lg:h-16 rounded-full ${item.color} bg-opacity-20 flex items-center justify-center mb-2 lg:mb-3 transition-all duration-300 group-hover:scale-110 shadow-md border border-opacity-50 ${item.color.replace('text', 'border')}`}>
                <item.icon className={`w-6 h-6 lg:w-8 lg:h-8 ${item.color}`} />
              </div>
              <span className="text-sm lg:text-base font-medium text-gray-800 group-hover:text-gray-900 transition-colors duration-300">{item.text}</span>
            </motion.div>
          ))}
        </div>
        <motion.div
          className="mb-8"
          variants={containerVariants}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
        >
          <motion.h3
            className="text-2xl font-bold mb-6 text-center text-gray-800"
            variants={itemVariants}
          >
            Built by AI Research Engineers from
          </motion.h3>
          <div className="flex flex-wrap justify-center items-center gap-4 mb-8">
            {companies.map((company, index) => (
              <motion.div
                key={index}
                className="bg-white p-3 rounded-lg shadow-md"
                whileHover={{ scale: 1.05, rotate: 5 }}
                whileTap={{ scale: 0.95 }}
                variants={itemVariants}
              >
                <img
                  src={company.image}
                  alt={company.name}
                  className="h-12 w-12 object-contain"
                  title={company.name}
                />
              </motion.div>
            ))}
          </div>
        </motion.div>
        <motion.div
          className="bg-white rounded-lg shadow-md p-4 lg:p-6 transition-all duration-300 hover:shadow-lg"
          whileHover={{ scale: 1.02 }}
        >
          <a href="https://lu.ma/p5qmq3x9" target="_blank" rel="noopener noreferrer" className="flex items-center justify-between text-gray-800 hover:text-blue-600 transition-colors duration-300">
            <div>
              <h3 className="font-bold text-lg lg:text-xl mb-2">RAG Security : Indirect Prompt Injections</h3>
              <p className="text-sm lg:text-base text-gray-600">Watch the talk from our CEO at Zoom's AI event</p>
            </div>
            <ExternalLink className="w-6 h-6 lg:w-8 lg:h-8 flex-shrink-0 ml-4" />
          </a>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MainBanner;