import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Mail, Twitter, Linkedin } from 'lucide-react';



const Footer = () => {
  const iconVariants = {
    hover: {
      scale: 1.2,
      rotate: 15,
      transition: { type: 'spring', stiffness: 300 }
    }
  };

  const links = [
    { href: "https://cal.com/sachdh/15min", icon: Calendar, label: "Schedule on Calendly" },
    { href: "mailto:contact@trojanvectors.com?subject=Trojan%20Vectors%20Inquiry", icon: Mail, label: "Send Email" },
    { href: "https://twitter.com/TrojanVectors", icon: Twitter, label: "Follow on Twitter" },
    { href: "https://www.linkedin.com/company/trojanvectors/", icon: Linkedin, label: "Connect on LinkedIn" }
  ];

  return (
    <footer className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-gray-800 to-gray-900 text-gray-300 p-4 shadow-lg z-50">
      <div className="max-w-7xl mx-auto flex justify-center items-center">
        {links.map((link, index) => (
          <motion.a
            key={index}
            href={link.href}
            className="mx-3 sm:mx-4 hover:text-white transition-colors duration-300"
            target="_blank"
            rel="noopener noreferrer"
            aria-label={link.label}
            whileHover="hover"
            variants={iconVariants}
          >
            <link.icon size={24} />
          </motion.a>
        ))}
      </div>
      <motion.div
        className="absolute bottom-full left-0 right-0 h-0.5 bg-gradient-to-r from-blue-400 to-purple-400"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
      />
    </footer>
  );
};

export default Footer;